import {Component, Provide, Vue} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import {CollectionList} from "@/interface/pagas/mine";
import {DeleteCollectionApi, GetMyCollectionApi} from "@/network/modules/mine";
import {DeleteCollectionReq, GetMyCollectionReq} from "@/interface/res/mine";


@Component({
  components: {
    common
  },
  computed: {
  }
})
export default class Collect extends Vue {
  @Provide() condition:GetMyCollectionReq = {
    onshelfStatus:1,
    pageNum:1,
    pageSize:6,
  }
  @Provide() loading:boolean = false
  @Provide() titleIndex:number = 0
  @Provide() titleList:Array<{id:number,name:string}> = [{id:1,name:'全部'},{id:0,name:'失效'}]
  @Provide() list:Array<CollectionList> = []
  @Provide() listTotal:number = -1
  @Provide() deleteShow:boolean = false
  @Provide() deleteLoading:boolean = false
  @Provide() deleteIndex:number = -1
  @Provide() deleteItem:CollectionList = {
    collectionId:-1,
    commodityId:-1,
    commoditySeries:-1,
    commodityType:-1,
    onshelfStatus:-1,
    commodityName:'',
    commodityShortImg:''
  }


  created(){
    const _this:any = this;
    _this.GetMyCollectionFn()
  }

  /**
   * @Author HS
   * @Date 2021/7/9 5:19 下午
   * @Description: 收藏列表
   * @Params: null
   * @Return: null
  */
  async GetMyCollectionFn(){
    const _this:any = this;
    _this.loading = true
    let params:GetMyCollectionReq = _this.condition
    const res = await GetMyCollectionApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, msg, total } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list = [..._this.list,...data]
    _this.listTotal = total
  }


  /**
   * @Author HS
   * @Date 2021/7/9 5:16 下午
   * @Description: 类型切换
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  typeChangeFn(index:number){
    const _this:any = this;
    _this.titleIndex = index
    _this.condition.onshelfStatus = _this.titleList[index].id
    _this.condition.pageNum = 1
    _this.list = []
    _this.listTotal = -1
    _this.GetMyCollectionFn()
  }

  /**
   * @Author HS
   * @Date 2021/7/12 2:40 下午
   * @Description: 点击查看更多
   * @Params: null
   * @Return: null
   */
  loadingMoreFn(){
    const _this:any = this;
    _this.condition.pageNum++
    _this.GetMyCollectionFn()
  }

  /**
   * @Author HS
   * @Date 2021/7/12 3:10 下午
   * @Description: 弹窗删除
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  deleteShowFn(index:number){
    const _this = this;
    _this.deleteIndex = index
    _this.deleteItem = _this.list[index]
    _this.deleteShow = true
  }

  /**
   * @Author HS
   * @Date 2021/7/12 3:04 下午
   * @Description: 删除当前数据
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  async deleteItemFn(index:number){
    const _this:any = this;
    _this.deleteLoading = true
    let params:DeleteCollectionReq = {
      commodityIds: _this.deleteItem.commodityId
    }
    const res = await DeleteCollectionApi(params)
    let a = setTimeout(()=>{_this.deleteLoading = false;clearTimeout(a)},500)
    const { code , msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.deleteShow = false
    _this.condition.pageNum = 1
    _this.list = []
    _this.listTotal = -1
    _this.GetMyCollectionFn()
  }

  /**
   * @Author HS
   * @Date 2021/7/12 5:45 下午
   * @Description: 跳转到详情
   * @Params: { number ： id - true } [id]
   * @Return: null
  */
  goDetails(id:number,val:any){
    const _this:any = this;
    const link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${val.commoditySeries}&i=`+id.toString())
    _this.$base.goRouter(link)
  }

}
